import wretch from 'wretch';
import config from './config.js';

export const api = wretch().url(config.api);


let backupStore = {};
export function setSessionStorage(key, value) {
    try {
        sessionStorage.setItem(key, value);
    } catch {
        backupStore[key] = value;
    }
}

export function getSessionStorage(key) {
    try {
        return sessionStorage.getItem(key);
    } catch{
        return backupStore[key];
    }
}

