<script>
	export let users;

	const maxAvatarCircles = 6;
	const maxHiddenUsersWithNames = 10;

	$: usersMapped = users.map((u) => ({ ...u, usingAvatar: u.avatar.includes('/img/avatars/') }));
	$: registeredWithImage = usersMapped.filter((u) => !u.isGuest && !u.usingAvatar);
	$: registeredWithAvatar = usersMapped.filter((u) => !u.isGuest && u.usingAvatar);
	$: guestUsers = usersMapped.filter((u) => u.isGuest);
	$: orderedUsers = [...registeredWithImage, ...registeredWithAvatar, ...guestUsers];
	$: firstSixUsers = orderedUsers.slice(0, maxAvatarCircles);
	$: hiddenUserCount = usersMapped.length - firstSixUsers.length + 1;
	$: stackUsers = hiddenUserCount > 1;
	$: usersToDisplay = firstSixUsers.slice(0, stackUsers ? maxAvatarCircles - 1 : maxAvatarCircles);
	$: hiddenUsers = orderedUsers.slice(usersToDisplay.length);
	$: hiddenRegisteredUsers = hiddenUsers.filter((u) => !u.isGuest);
	$: hiddenUserNames = hiddenRegisteredUsers.slice(0, maxHiddenUsersWithNames).map((u) => u.name);
	$: moreHiddenUserNameCount = hiddenUsers.length - hiddenUserNames.length;
	$: hiddenUserBoxHasOneLineOnly = !hiddenUserNames.length && moreHiddenUserNameCount;
</script>

<style src="./Presence.less">

</style>

<div id="huddled-presence">
	{#each usersToDisplay as { name, avatar, color, usingAvatar }}
		<div class="huddled-container">
			<div class="huddled-background" style="background: {color};" />
			<div class="huddled-name">{name}</div>
			<div class="huddled-circle" style="border-color: {color};">
				{#if usingAvatar}
					<img src={avatar} alt={name} />
				{:else}
					<div class="huddled-profile-picture" style="background-image: url({avatar});" />
				{/if}
			</div>
		</div>
	{/each}
	{#if stackUsers}
		<div class="huddled-container">
			{#if hiddenUsers.length}
				<div class="huddled-name huddled-list" class:single={hiddenUserBoxHasOneLineOnly}>
					{#each hiddenUserNames as name}
						{name}
						<br />
					{/each}
					{#if moreHiddenUserNameCount}+{moreHiddenUserNameCount} others{/if}
				</div>
			{/if}
			<div class="huddled-circle huddled-stacked-users">+{hiddenUserCount}</div>
		</div>
	{/if}

</div>
