const config = {
    production: {
        api: 'https://huddled.io/api',
    },
    staging: {
        api: 'https://huddled.io/api',
    },
    development: {
        api: 'http://localhost:3000/api',
    },
};

const env = process.env.NODE_ENV || 'development'
console.log(`Loading [${env}] config`);

export default config[env];
